<template>
    <div class="page">
        <!-- <div class="form-box">
            <p class="f-fc1 f-fs16 f-fwb mb-50">请输入录播主机机身号</p>
            <van-field input-align="center" v-model="formData.deviceNo" placeholder="请注意区分字母大小写" />
            <van-button class="btn" type="primary" @click="bindMobile">查询</van-button>
        </div> -->

        <div>
            <!-- 点击按钮触发拍照事件 -->
            <van-button class="btn" type="primary" @click="takePhoto">拍照</van-button>
            
            <!-- 显示拍到的图片 -->
            <img :src="photoUrl" alt="拍摄的照片">
            
            <van-uploader v-model="image" :max-count="3" :after-read="afterRead" :before-delete="afterDelete" :before-read="beforeRead">
                <van-button icon="plus" type="primary">上传文件</van-button>
            </van-uploader>
        </div>


    </div>
</template>

<script>
import mix1 from '@/mixins/page'
import { uiShowLoading, uiHideLoading, uiAlert, uiConfirm } from '@/utils/ui'
import { delayAsync } from '@/utils/common'
import { Button, NumberKeyboard, Field, Uploader } from 'vant'
import MgPopup from '@/components/common/mg-popup'

export default {
    mixins: [mix1],
    components: {
        MgPopup, Button, NumberKeyboard,
        [Field.name]: Field,
        [Button.name]: Button,
        [Uploader.name]: Uploader,
    },
    data() {
        return {
            image: [],
            photoUrl: '' // 存放拍摄后的图片URL
        }
    },
    methods: {
        async beforeRead(file){
            let data = file.content
            console.log('file', file)
            console.log('data', data)
        },
        afterDelete(...args) {
            console.log('args', args)
            // let index = args[1].index
            // this.detailInfo.items[this.upLoadIndex].imgs.splice(index, 1)
            return true
        },
        afterRead(...args){
            console.log('args', args)
            let size = 2097152
            if(args[0].size > size){
            this.uiAlert('上传图片不能大于2MB')
            return
            }
            // return true
        },
        takePhoto() {
            const input = document.createElement('input');
            
            // 设置输入类型为文件并限制只能选取图像文件
            input.type = 'file';
            input.accept = 'image/*';
            
            // 当用户选择了图像文件时触发change事件
            input.addEventListener('change', (event) => {
                if (!event.target.files || event.target.files.length === 0) {
                    console.log("未选择任何图像文件");
                    return;
                }
                
                // 创建FileReader对象
                const reader = new FileReader();
                console.log('reader', reader)
                // 将选定的图像文件转换为Data URL格式
                reader.onloadend = () => {
                    this.photoUrl = reader.result;
                };
                
                // 开始读取图像文件
                reader.readAsDataURL(event.target.files[0]);
            });
            
            // 模拟点击上传按钮
            input.dispatchEvent(new MouseEvent('click'));
        },
        async bindMobile() {
            let formData = this.formData
            const promise = this.apiGet('device.warranty', formData)

            if (this.isEmpty(formData.deviceNo)) {
                uiAlert('请填写主机机身号')
                return
            }
            uiShowLoading()
            await delayAsync(500)

            let apiResult = null

            try {
                apiResult = await promise
            } catch (ex) {
                apiResult = null
            }

            uiHideLoading()

            if (this.hasException(apiResult)) {
                return
            }

            this.deviceWarranty = apiResult.obj
            this.isDeviceWarranty = true
        },

    },
    mounted() {

    },
    created() {
        let query = this.$route.query
    }
}
</script>

<style lang="less" scoped>
.page {
    .camera {
    margin-top: 10px;
    }
    .camera video {
    border-radius: 5px;
    }
    .img_body {
    width: 100%;
    }
    .img_content {
    width: 100%;
    display: flex;
    }
    .image {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .image img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    }
}
</style>